import { trackingEvent } from "./common";

export const trackCta = (trackedCta, eventGA4) => {
  const blocName = trackedCta.getAttribute('data-name') ? trackedCta.getAttribute('data-name').toLowerCase() : null;
  const blocInteraction = trackedCta.getAttribute('data-interaction') ? trackedCta.getAttribute('data-interaction').toLowerCase() : null;
  const blocType = trackedCta.getAttribute('data-type') || null;

  if (blocName && blocInteraction) {
    if (blocType === 'enroll') {
      // ENROLL
      eventGA4.eventName = 'cta_enroll';
      eventGA4.event_data = {
        'enroll_context' : blocName,
        'enroll_type': blocInteraction,
      };
    }
    else {
      // CTA
      eventGA4.eventName = 'bloc_interact';
      eventGA4.event_data = {
        'bloc_name' : blocName,
        'bloc_interaction': blocInteraction
      }

      if (blocType) {
        eventGA4.event_data.bloc_type = blocType;
      }
    }
  }

  trackingEvent.addPageName(eventGA4);
}
