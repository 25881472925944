import TagManager from '@accor/core-services/dist/gtm'
import { trackPage } from './services/datalayer'
import { trackingEvent } from './services/events/common'

function initTracking() {
  TagManager.initDefaultAutoTracking()
  if (document.getElementsByTagName('body')[0]) {
    trackPage()
    trackingEvent.addCTAListener()
  }
}

document.addEventListener('DOMContentLoaded', function() {
  initTracking()
})

if (window.__NUXT__) {
  initTracking()
}
