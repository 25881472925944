import { getPagename } from "../page-attributes";
import { trackCta } from "./cta";
import { trackNewsletter, trackFormCta } from "./form";

export const trackingEvent = {
  initTracking: () => {
    const eventGA4 = {};

    eventGA4.event = 'GA4event';
    eventGA4.event_data = {};

    return eventGA4;
  },
  addPageName: (eventGA4) => {
    eventGA4.event_data.pagename = getPagename();

  TagManager.trackEvent(eventGA4);
  },
  addCTAListener: () => {
    const cta = document.querySelectorAll('[tracked]');
  
    if (cta.length > 0) {
      Array.prototype.forEach.call(cta, function(i) {
        i.addEventListener('click', trackingEvent.trackEvent);
      });
    }
  },
  trackEvent: async (cta) => {
    const trackedCta = cta.currentTarget;
    const eventGA4 = trackingEvent.initTracking();

    if (trackedCta.closest('form') && trackedCta.closest('form').getAttribute('data-name') === 'newsletter') {
      trackNewsletter(eventGA4);
    }
    if (trackedCta.closest('form') && trackedCta.getAttribute('tracked') !== "true") {
      trackFormCta(trackedCta, eventGA4);
    }
    else if (!trackedCta.closest('form') || trackedCta.getAttribute('tracked') === 'cta') {
      trackCta(trackedCta, eventGA4);
    }
  }
};
