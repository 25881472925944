import { trackingEvent } from "./common";
import { getPageAttributes } from "../page-attributes";

export const trackBookingError = (errors) => {
  let errorFieldsName = [];
  let errorFieldsType = [];

  for (const errorField of errors) {
    errorFieldsName.push(errorField.input.getAttribute('name'));
    errorFieldsType.push(errorField.type === 'required' ? 'blank field' : 'incorrect value');
  }

  trackFormError(errorFieldsName, errorFieldsType, 'booking_form');
}

export const trackBookingBackError = (backError) => {
  trackFormError(backError, ['incorrect value'], 'booking_form');
}

export const trackContactError = (errors) => {
  const errorFieldsName = errors.name;
  const errorFieldsType = errors.type.map(item => item === 'required' || item === 'checkbox' ? 'blank field' : 'incorrect value');

  trackFormError(errorFieldsName, errorFieldsType, 'contact');
}

const trackFormError = (errorFieldsName, errorFieldsType, form) => {
  const eventGA4 = trackingEvent.initTracking();
  eventGA4.eventName = form + '_interact';

  if (errorFieldsName.length > 0) {
    eventGA4.event_data = {
      'form_action': 'error',
      'bloc_name': 'form',
      'error_type': errorFieldsType.filter((value, index, array) => array.indexOf(value) === index).toString(),
      'error_field': errorFieldsName.filter((value, index, array) => array.indexOf(value) === index).toString()
    };
  }

  trackingEvent.addPageName(eventGA4);
}

export const trackFormSuccess = (form) => {
  const eventGA4 = trackingEvent.initTracking();
  const site = getPageAttributes().dataSite;
  eventGA4.eventName = form + '_submit';

  if (form === 'contact') {
    eventGA4.event_data.contact_name = site;
  }

  trackingEvent.addPageName(eventGA4);
}

export const trackFormStep = () => {
  const eventGA4 = trackingEvent.initTracking();
  eventGA4.eventName = 'contact_interact';

  eventGA4.event_data = {
    'form_action': 'view',
    'bloc_name': 'form'
  };

  trackingEvent.addPageName(eventGA4);
}

export const trackNewsletter = (eventGA4) => {
  const site = getPageAttributes().dataSite;
  eventGA4.eventName = 'newsletter_submit';
  eventGA4.event_data.newsletter_name = site;

  trackingEvent.addPageName(eventGA4);
}

export const trackFormCta = (trackedCta, eventGA4) => {
  const formName = trackedCta.closest('form').getAttribute('data-name') ? trackedCta.closest('form').getAttribute('data-name') : 'form';
  const blocName = trackedCta.getAttribute('tracked');

  eventGA4.eventName = formName + '_interact';
  eventGA4.event_data = {
    'form_action': blocName,
  };

  trackingEvent.addPageName(eventGA4);
}
